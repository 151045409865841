import React from "react";
import { Button, Col, Form, Row, Typography } from "antd";
import Trans from "../../../Trans";
import GetInput from "../../../templates/FormInput";

const { Title } = Typography;

export const FilterForm = (props) => {
  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back",
    initialValues: props.initialValues,
  };

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col
          style={{ marginBottom: "15px" }} xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Title level={4}>{props.title}</Title>
        </Col>
        {props?.inputs.map((input, index) => {
          return (
            <Col key={index} xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
              <GetInput  {...input} />
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => {
              props.form.resetFields();
              props.form.submit();
            }}
          >
            <Trans alias={"Reset"} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
