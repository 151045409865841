import { Col, Row } from "antd";
import { useEffect } from "react";
import { getTitle } from "../../../../../Trans";
import MonitoringTable from "../../../../../templates/MonitoringTable";
import HighChartComponent from "../../../../../templates/HightChartComponent";

const Statistic_02 = (props) => {

    const titleCount = getTitle('Count');
    const titleName = getTitle('name');
    const pageSize = 15;
    let currentPage = 1;

    const columns = [
        {
            title: "№",
            dataIndex: "index",
            key: "index",
            width: 35,
            render: (text, record, index) => (currentPage - 1) * pageSize + (index + 1),
            align: 'center'
        },
        {
            title: titleName,
            dataIndex: "name",
            key: "name",
        },
        {
            title: titleCount,
            dataIndex: "count",
            key: "count",
            align: 'center'
        },
    ];

    useEffect(() => {
        props.setColumns(columns)
    }, []);

    const pieData = props.dataSource.map((value) => (value = { name: value.name, y: value.count }));
    return (
        <Row gutter={5}>
            <Col xxl={10} xl={10} lg={10} md={24} sm={24} xs={24}>
                <MonitoringTable
                    size={(props.type==='12'||props.type==='13')?'small':'middle'}
                    columns={columns}
                    dataSource={props.dataSource}
                    loading={props.loading}
                    pagination={{ defaultPageSize: pageSize, onChange: (page) => { currentPage = page }}}
                    bordered={true}
                    rowKey={(record) => record.name} />
            </Col>
            <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
                <Row>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <HighChartComponent type='pie' title={titleCount}
                            series={[{ name: '', data: pieData }
                            ]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Statistic_02;

