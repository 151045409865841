import { Col, Row } from "antd";
import { useEffect } from "react";
import { getTitle } from "../../../../../Trans";
import MonitoringTable from "../../../../../templates/MonitoringTable";
import HighChartComponent from "../../../../../templates/HightChartComponent";

const Statistic_03 = (props) => {

    const titleCount = getTitle('Count');
    const titleName = getTitle('name');
    const titleClient = getTitle('Client code');

    const columns = [
        {
            title: titleClient,
            dataIndex: "client_code",
            key: "client_code",
            align: 'center'
        },
        {
            title: titleName,
            dataIndex: "client_name",
            key: "client_name",
        },
        {
            title: titleCount,
            dataIndex: "dec_count",
            key: "dec_count",
            align: 'center'
        },
    ];

    useEffect(() => {
        props.setColumns(columns)
    }, []);

    const pieData = props.dataSource.map((value) => (value = { name: value.client_name, y: value.dec_count }));

    return (
        <Row gutter={5}>
            <Col xxl={10} xl={10} lg={10} md={24} sm={24} xs={24}>
                <MonitoringTable
                    size={(props.type==='10')?'small':'middle'}
                    columns={columns}
                    dataSource={props.dataSource}
                    loading={props.loading}
                    pagination={true}
                    bordered={true}
                    rowKey={(record) => record.client_name} />
            </Col>
            <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
                <Row>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <HighChartComponent type='pie' title={titleCount}
                            series={[{ name: '', data: pieData }]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Statistic_03;

