import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorsApi } from "./web/errors";
import AppStore from "./redux/store/AppStore";
import AppRoutes from "./router/AppRoutes";
import { NewFulllHeaderNav } from "./site/header/components/NewFulllHeaderNav";

const App = () => {
  return (
    <Provider store={AppStore}>
      <ErrorBoundary FallbackComponent={ErrorsApi}>
        <AppRoutes />
        <div id="forModal"></div>
      </ErrorBoundary>
    </Provider>
  );
};
export default App;
