import { Col, Row, Statistic } from "antd";
import { useEffect, useState } from "react";
import { getTitle } from "../../../../../Trans";
import MonitoringTable from "../../../../../templates/MonitoringTable";
import HighChartComponent from "../../../../../templates/HightChartComponent";

const Statistic_04 = (props) => {

    const titleCount = getTitle('Count');
    const titleYear = getTitle('Year');
    const titleName = getTitle('name');
    const columns = [
        {
            title: "№",
            dataIndex: "index",
            key: "index",
            width: 35,
            render: (text, record, index) => (index + 1),
            align: 'center'
        },
        {
            title: titleYear,
            dataIndex: "year",
            key: "year",
            align: 'center'
        },
        {
            title: titleName,
            dataIndex: "name",
            key: "name",
        },
        {
            title: titleCount,
            dataIndex: "dec_count",
            render: (text, record, index) => (<Statistic value={text} precision={0} groupSeparator={' '} valueStyle={{ fontSize: '14px' }} />),
            key: "dec_count",
            align: 'center'
        },
    ];
    const [chartData, setChartDate] = useState([]);
    useEffect(() => {
        props.setColumns(columns);
    }, []);

    useEffect(() => {
        createDataChart();
    }, [props.dataSource]);

    const createDataChart = async () => {
        let strArr = [];
        let objArr = [];
        props.dataSource.forEach(element => {
            let obj = {};
            let ind = strArr.indexOf(element.name)
            if (ind === -1) {
                strArr.push(element.name);
                obj.name = element.name;
                obj.data = [[element.year, element.dec_count]]
                objArr.push(obj);
            }
            else {
                obj = objArr[ind];
                if (element.year)
                    obj.data.push([element.year, element.dec_count])
                objArr[ind] = obj;
            }
        });
        setChartDate(objArr);
    }
    return (
        <Row gutter={5}>
            <Col xxl={10} xl={10} lg={10} md={24} sm={24} xs={24}>
                <MonitoringTable
                    size='middle'
                    columns={columns}
                    dataSource={props.dataSource}
                    loading={props.loading}
                    pagination={true}
                    bordered={true}
                    rowKey={(record) => record.client_name} />
            </Col>
            <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
                <Row>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <HighChartComponent type='spline' title={''}
                            xAxis={{ type: 'category', categories: '', crosshair: true }}
                            yAxis={{ min: 0, title: { text: '' } }}
                            pointStart={2014}
                            series={chartData}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Statistic_04;

