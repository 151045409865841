import { saveUserSession, removeUserSession } from "../../web/cookies";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, tokenConfig } from "../../web/api";
import { ErrorsApi } from "../../web/errors";
import { usersPriveleges } from "../../site/constants/priveleges";
export const lang = localStorage.getItem("lang");
const location = localStorage.getItem("location");

export const login = createAsyncThunk("user/login", async (params) => {
  try {
    params.lang = localStorage.getItem("lang");
    const data = await api.post("/user/login", params);
    if (data.data.code !== 0) {
      ErrorsApi(data.data.msg, "alert");
    } else {
      if (location === "/registration" || location?.replace(/\/?[0-9]*\/?$/, "") === "/activate") {
        window.location.replace("/");
      }
      saveUserSession(data.data.token);
      ErrorsApi(data.data.msg, "success");
      return data.data;
    }
  } catch (err) {
    ErrorsApi(err);
  }
});

export const refresh = createAsyncThunk("user/refresh", async () => {
  try {
    const data = await api.get(`/user/refresh/${lang}`);
    if (data.data.code !== 0) {
      ErrorsApi(data.data.msg, "alert");
    } else {
      saveUserSession(data.data.token);
      return data.data;
    }
  } catch (err) {
    if (err.response.status === 401) {
      removeUserSession();
      ErrorsApi(err.response.data.msg, "alert");
      window.location.reload();
    } else {
      ErrorsApi(err, true);
      removeUserSession();
    }
  }
});

export const getUserPrivileges = createAsyncThunk("user/getUserPrivileges", async (_, thunkAPI) => {
  try {
    const data = await api.post("/user/getUserPrivileges", { lang }, tokenConfig());
    return data.data.data;
  } catch (err) {
    const {
      auth: { refreshToken },
    } = thunkAPI.getState();
    if (err.response.status === 401) {
      removeUserSession();
      ErrorsApi(err.data.msg, "alert");
      window.location.reload();
    } else if (err.response.status === 403 && err.response.data.msg === "jwt expired") {
      if (refreshToken === "idle" || refreshToken === "rejected") {
        thunkAPI.dispatch(refresh());
      }
    } else {
      ErrorsApi(err, true);
      removeUserSession();
    }
  }
});

export const logOut = createAsyncThunk("user/logout", async () => {
  try {
    const data = await api.post("/user/logout", {}, tokenConfig());
    removeUserSession();
    return data.data;
  } catch (err) {
    if (err.response.status === 401) {
      removeUserSession();
    }
    ErrorsApi(err);
  } finally {
    window.location.reload();
  }
});

const accessList = (data) => {
  let result = {};
  Object.entries(usersPriveleges).forEach(([key, val]) => {
    data.forEach((element) => {
      if (val === element) {
        result[key] = true;
      }
    });
  });
  return result;
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    status: false,
    loading: false,
    auth: false,
    data: [],
    userState: false,
    refreshToken: "idle",
    client_role: null,
    access: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = true;
        state.user = null;
        state.auth = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = false;
          state.user = action.payload.data.user_id;
          state.auth = true;
        } else {
          state.status = false;
          state.user = null;
          state.auth = false;
        }
      })
      .addCase(login.rejected, (state) => {
        state.status = false;
        state.user = null;
        state.auth = false;
      })
      // .addCase(accessList.pending, (state,action) => {
      //     state.access = action.payload
      // })
      .addCase(getUserPrivileges.pending, (state) => {
        state.data = [];
        state.user = null;
        state.userState = false;
      })
      .addCase(getUserPrivileges.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.privileges;
          state.user = action.payload.user_id;
          state.client_role = action.payload.client_role[0];
          state.userState = true;
          state.access = accessList(action.payload.privileges);
        } else {
          state.data = [];
          state.user = null;
          state.userState = false;
        }
      })
      .addCase(getUserPrivileges.rejected, (state) => {
        state.data = [];
        state.userState = false;
      })
      .addCase(logOut.pending, (state) => {
        state.loading = true;
        state.auth = true;
      })
      .addCase(logOut.fulfilled, (state, action) => {
        state.data = [];
        state.loading = false;
        state.auth = false;
        state.user = null;
        state.userState = false;
      })
      .addCase(logOut.rejected, (state) => {
        state.data = [];
        state.loading = false;
        state.auth = false;
        state.user = null;
        state.userState = false;
      })
      .addCase(refresh.pending, (state) => {
        state.userState = false;
        state.status = false;
        state.user = null;
        state.auth = false;
        state.refreshToken = "pending";
      })
      .addCase(refresh.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = false;
          state.user = action.payload.user_id;
          state.auth = true;
          state.refreshToken = "fulfilled";
        }
        // else {
        //     state.status = false
        //     state.user = null
        //     state.auth = false
        //     state.refreshToken = 'rejected'
        // }
      })
      .addCase(refresh.rejected, (state) => {
        state.userState = false;
        state.status = false;
        state.user = null;
        state.auth = false;
        state.refreshToken = "rejected";
      });
  },
});
export const selectStatus = (state) => state.auth.status;
export const selectAuth = (state) => state.auth.auth;
export const userAuth = (state) => state.auth.userState;
export const userEntitlement = (state) => state.auth.data;
export const access_ = (state) => state.auth.access;
export const selectLogOut = (state) => state.auth.loading;
export const getUserId = (state) => state.auth.user;
export const refreshToken = (state) => state.auth.refreshToken;
export const client_role = (state) => state.auth.client_role;
export default AuthSlice.reducer;
