import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Form, Divider } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import { date_format } from "../../../templates/Patterns";
import Statistic01 from "./components/table/Statistic_01";

const StaticticsForm = () => {

    const [filter] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [excel, setExcel] = useState(false);
    const [selectLabel, setSelectLabel] = useState();
    const [fileName, setFileName] = useState('');
    const [columns, setColumns] = useState([]);
    const [type, setType] = useState();
    const loading = useSelector(loadingState);
    const hookApi = ApiHooks();
    const pageSize = 20;
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const defaultFilterForm = { bdate: moment().startOf('month'), edate: moment() }
    const [sendObj, setSendObj] = useState({ bdate: moment().startOf('month'), edate: moment(), stat_code: null, user_name: null, page: 1, page_size: pageSize, excel: 0 });
    const type_ = Form.useWatch('type', filter);

    useEffect(() => {
        getStatistic();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendObj]);

    useEffect(() => {
        setCurrentPage(1);
        setDataCount(0)
    }, [type_]);

    const getStatistic = async () => {
        if (sendObj.stat_code) {
            sendObj.excel = 0;
            const result = await hookApi('statistic/getClientAction', { data: sendObj });
            if (result.code === 0) {
                setDataCount(result.data.count);
                setDataSource(result.data.data);
                setExcel(true);
                setFileName(selectLabel);
            }
        }
    };

    const handleChangePage = async (value) => {
        setDataSource([]);
        setCurrentPage(value);
        setSendObj({ ...sendObj, page: value });
    };

    const handleFilterForm = async (values) => {
        let data = {};
        data.bdate = values.bdate ? moment(values.bdate).format(date_format) : null;
        data.edate = values.edate ? moment(values.edate).format(date_format) : null;
        data.user_name = values.user_name ? values.user_name : null;
        data.stat_code = values.type;
        data.page = 1;
        setCurrentPage(1);
        setSendObj({ ...sendObj, ...data })
    };

    return (
        <>
            <FilterForm
                sendObj={sendObj}
                form={filter}
                onFinish={handleFilterForm}
                initialValues={defaultFilterForm}
                disabled={loading}
                resetTable={setDataSource}
                setExcel={setExcel}
                excel={excel}
                selectLabel={setSelectLabel}
                setColumns={setColumns}
                columns={columns}
                fileName={fileName}
                dataSource={dataSource}
                setType={setType}
            />
            <Divider />
            <Statistic01 dataSource={dataSource} setColumns={setColumns} title={fileName} type={type} handleChangePage={handleChangePage} dataCount={dataCount} currentPage={currentPage} pageSize={pageSize} />
        </>
    );
};

export default StaticticsForm;
