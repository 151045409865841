import { Col, Row, Statistic } from "antd";
import { useEffect } from "react";
import { getTitle } from "../../../../../Trans";
import MonitoringTable from "../../../../../templates/MonitoringTable";
import HighChartComponent from "../../../../../templates/HightChartComponent";

const Statistic_01 = (props) => {

    const titleSum = getTitle('Sum');
    const titleCount = getTitle('Count');
    const titleYear = getTitle('Year');

    const columns = [
        {
            title: "№",
            dataIndex: "index",
            key: "index",
            width: 35,
            render: (text, record, index) => (index + 1),
            align: 'center'
        },
        {
            title: titleYear,
            dataIndex: "year",
            key: "year",
            align: 'center'
        },
        {
            title: titleCount,
            dataIndex: "dec_count",
            render: (text, record, index) => (<Statistic value={text} precision={0} groupSeparator={' '} valueStyle={{ fontSize: '14px' }} />),
            key: "dec_count",
            align: 'center'
        },
        {
            title: titleSum,
            dataIndex: "amount_sum",
            render: (text, record, index) => (<Statistic value={text} precision={2} groupSeparator={' '} valueStyle={{ fontSize: '14px' }} />),
            key: "amount_sum",
            align: 'center'
        }
    ];

    useEffect(() => {
        props.setColumns(columns)
    }, []);

    const categories = props.dataSource.map((value) => value.year);
    const amount_sum = props.dataSource.map((value) => Number(value.amount_sum));
    const dec_count = props.dataSource.map((value) => value.dec_count);

    return (
        <Row gutter={5}>
            <Col xxl={10} xl={10} lg={10} md={24} sm={24} xs={24}>
                <MonitoringTable
                    size='middle'
                    columns={columns}
                    dataSource={props.dataSource}
                    loading={props.loading}
                    pagination={true}
                    bordered={true}
                    rowKey={(record) => record.year} />
            </Col>
            <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
                <Row>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <HighChartComponent type='spline' title={titleSum}
                            xAxis={{ type: 'category', categories: categories, crosshair: true }}
                            yAxis={{ min: 0, title: { text: '' } }}
                            series={[{ name: titleSum, data: amount_sum }
                            ]} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <HighChartComponent type='column' title={titleCount}
                            xAxis={{ type: 'category', categories: categories, crosshair: true }}
                            yAxis={{ min: 0, title: { text: '' } }}
                            series={[{ name: titleCount, data: dec_count }
                            ]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Statistic_01;

