
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from '../../web/api';
import { ErrorsApi } from '../../web/errors';

export const getRefers = createAsyncThunk('user/getRefers', async (code) => {
    try {
        let arr = []
        const  data  = await api.post('/refers/getRefers',{refer:code,lang:localStorage.getItem("lang")});
        if (data.data) {
            JSON.parse(data.data).map((refers) => (
                arr.push({ value: refers.code, label: refers.code + '-' + refers.name })
            ));
        }
        return arr;
    }
    catch (err) {
        ErrorsApi(err);
    }
});


const RefersSlice = createSlice({
    name: 'refers',
    initialState: {
        mfo: [],
        client_type:[],
        status: false
    },
    reducers: {
        
    },
    extraReducers(builder) {
        builder
            .addCase(getRefers.pending, (state) => {
                state.status = false;
                state.mfo = [];
                state.client_type =[];
            })
            .addCase(getRefers.fulfilled, (state, action) => {
                // switch(action.meta.arg){
                //     case'006':
                //     state.client_type = action.payload;
                //     case'014':
                //     state.mfo = action.payload;
                // }
                if(action.meta.arg==='006'){
                    state.client_type = action.payload;
                }
                if(action.meta.arg==='014'){
                    state.mfo = action.payload;
                }
                state.status = true

            })
            .addCase(getRefers.rejected, (state) => {
                state.status = false;
                state.mfo = [];
                state.client_type =[];
            })
    },
});

export const refClientType = (state) => state.refers.client_type;
export default RefersSlice.reducer;
