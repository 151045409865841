import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Button, Divider, Form, Modal, Pagination, Space, Switch } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import ApiHooks from "../../../../hooks/ApiHooks";
import Trans, { getTitle } from "../../../Trans";
import DrawerComponent from "../../../templates/DrawerComponent";
import MonitoringTable from "../../../templates/MonitoringTable";
import { FilterForm } from "../components/FilterForm";
import { ViewData } from "../components/ViewData";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import { pattern } from "../../../templates/Patterns";
import ModalComponent from "../../../templates/ModalComponent";

const UsersIndividual = () => {
  const [filter] = Form.useForm();

  const pageSize = 10;
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();

  const [dataDetail, setDataDetail] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false); //modal
  const [confirmActive, setConfirmActive] = useState(false); //modal
  const [confirmCancel, setConfirmCancel] = useState(false); //modal
  const [confirmEnter, setConfirmEnter] = useState(false); //modal
  const [confirmPrint, setConfirmPrint] = useState(false); //modal
  const [enterAndPrintState, setEnterAndPrintState] = useState();

  const [client_code, setClientCode] = useState();
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageParam, setPageParam] = useState({
    username: "",
    pin: "",
    client_code: null,
    page: 1,
    page_size: pageSize,
  });

  const [users, setUsers] = useState([]);

  const handleChangePage = (value) => {
    setCurrentPage(value);
    setPageParam({ ...pageParam, page: value });
  };

  const handleViewData = async (id) => {
    const result = await hookApi("user/getClientDetailed", {
      client_id: id,
    });
    const dataTable = <ViewData data={result?.data.client} username={result?.data.user.username} />;
    setDataDetail(dataTable);
    setModalOpen(true);
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  const getUsersIndividual = async () => {
    const users = await hookApi("user/getUsersIndividual", {
      json: pageParam,
    });

    setUsers(users?.data.data);
    setDataCount(users?.data.count);
  };

  const handleFilterForm = (values) => {
    const { pin, client_code, username } = values;
    setCurrentPage(1);
    setPageParam({ ...pageParam, pin, client_code, username, page: 1 });
  };

  const onConfirmState = async (id, state) => {
    const client = await hookApi("user/changeClientState", {
      json: {
        client_code: id,
        state,
      },
    });
    await getUsersIndividual();
    state === 1 && setConfirmActive(false);
    state === 2 && setConfirmCancel(false);
  };

  const getConfirm = (code, state) => {
    setClientCode(code);
    state === 2 && setConfirmActive(true);
    state === 1 && setConfirmCancel(true);
  };

  const confirmEditModal = (code) => {
    setClientCode(code);
    setModalEdit(true);
  };

  const getConfirmEnterPrint = (code, type, state) => {
    setClientCode(code);
    setEnterAndPrintState(state);
    type === 0 && setConfirmEnter(true);
    type === 1 && setConfirmPrint(true);
  };

  const getApiParam = (type, state) => {
    if (type === 0) {
      return { url: "changeClientEnterable", json: { enterable: state } };
    } else {
      return { url: "changeClientPrintable", json: { printable: state } };
    }
  };

  const onConfirmEnterPrint = async (code, type, state) => {
    const { url, json } = getApiParam(type, state);

    const client = await hookApi(`/user/${url}`, {
      json: {
        client_code: code,
        ...json,
      },
    });
    await getUsersIndividual();
    type === 0 && setConfirmEnter(false);
    type === 1 && setConfirmPrint(false);
  };

  useEffect(() => {
    getUsersIndividual();
  }, [pageParam]);

  const columns = [
    {
      title: "Код клиента",
      dataIndex: "client_code",
      key: "client_code",
      align: "center",
      width: "10%",
    },
    {
      title: "Пользователь",
      dataIndex: "client_name",
      key: "client_name",
      width: "10%",
    },
    {
      title: "Ф.И.О",
      dataIndex: "fio",
      key: "fio",
      width: "20%",
    },
    {
      title: getTitle("name"),
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: getTitle("Username"),
      dataIndex: "username",
      key: "username",
      width: "15%",
    },
    {
      title: "Доступ для ввода",
      dataIndex: "enterable",
      key: "enterable",
      align: "center",
      width: "10%",
      render: (record, data) => (
        <Switch
          checkedChildren="Да"
          unCheckedChildren="Нет"
          disabled={loading}
          checked={!!record}
          onClick={() => getConfirmEnterPrint(data.client_code, 0, record)}
        />
      ),
    },
    {
      title: "Доступ для изменения",
      dataIndex: "printable",
      key: "printable",
      align: "center",
      width: "10%",
      render: (record, data) => (
        <Switch
          checkedChildren="Да"
          unCheckedChildren="Нет"
          disabled={loading}
          checked={!!record}
          onClick={() => getConfirmEnterPrint(data.client_code, 1, record)}
        />
      ),
    },
    {
      title: "Статус",
      dataIndex: "status_name",
      key: "status_name",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Badge
          count={record.status_name}
          style={{
            backgroundColor: `${
              record.status_code === 1
                ? "#52c41a"
                : record.status_code === 2
                ? "#f5222d" //"#fa8c16"
                : "#f5222d"
            }`,
          }}
        />
      ),
    },

    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      fixed: "right",
      align: "left",
      width: "1%",
      render: (text, record) => (
        <Space>
          <Button
            disabled={loading}
            title="Просмотр"
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => handleViewData(record.client_code)}
          />

          {record.status_code === 1 && (
            <Button
              title="Редактировать"
              disabled={loading}
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => confirmEditModal(record.client_code)}
            />
          )}

          {record.status_code !== 0 && (
            <Button
              title="Активировать"
              disabled={loading || record.status_code === 1}
              type="success"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => getConfirm(record.client_code, record.status_code)}
            />
          )}

          <Button
            title="Заблокировать"
            disabled={loading || record.status_code === 2}
            type="danger"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={() => getConfirm(record.client_code, record.status_code)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <FilterForm
        form={filter}
        inputs={[
          { name: "username", text: "Username" },
          { name: "pin", text: "Pin", pattern: pattern.pin },
        ]}
        onFinish={handleFilterForm}
        disabled={loading}
      />

      <Divider />
      <MonitoringTable
        columns={columns}
        dataSource={users}
        loading={loading}
        bordered={true}
        pagination={false}
        size="small"
        rowKey={(record) => record.key}
      />
      <ModalComponent
        title={<Trans alias={"Изменения статуса"} />}
        open={confirmActive}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => onConfirmState(client_code, 1)}
        onCancel={() => setConfirmActive(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Изменения статуса"} />}
        open={confirmCancel}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => onConfirmState(client_code, 2)}
        onCancel={() => setConfirmCancel(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Изменения статуса"} />}
        open={modalEdit}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => {
          window.open(`/main/manage/client/update/${client_code}`, "_blank");
          setModalEdit(false);
        }}
        onCancel={() => setModalEdit(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Изменения статуса"} />}
        open={confirmEnter}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => onConfirmEnterPrint(client_code, 0, (enterAndPrintState === 0 && 1) || 0)}
        onCancel={() => setConfirmEnter(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Изменения статуса"} />}
        open={confirmPrint}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => onConfirmEnterPrint(client_code, 1, (enterAndPrintState === 0 && 1) || 0)}
        onCancel={() => setConfirmPrint(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <DrawerComponent title={<Trans alias={"Show data"} />} open={modalOpen} data={dataDetail} onCancel={onCancel} width={"50%"} />
      {dataCount > pageSize && (
        <Pagination
          // disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
        />
      )}
    </>
  );
};

export default UsersIndividual;
