export const REFERS = {
    conREF_MKOS: '001',
    conREF_SUBJECTS: '006',
    conREF_SEX: '007',
    conREF_BANKS: '012',
    conREF_OKED: '013',
    conREF_BRANCH: '014',
    conREF_REGIONS: '016',
    conREF_CURRENCY: '017',
    conREF_COUNTRY: '018',
    conREF_COAS: '019',
    conREF_CLIENT_TYPES: '021',
    conREF_HBRANCHS: '023',
    conREF_RESIDENT: '027',
    conREF_TYPE_COLLATERAL_LOAN: '033',
    conREF_LOCAL_REGIONS: '052',
    conREF_ORGANIZATION_FORMS: '063',
    conREF_SOATO: '104',
    conREF_WS_METHODS_BANK: '501',
    conREF_WS_METHODS_GNK: '502',
    conREF_WS_METHODS_MKO: '503',
    conREF_WS_METHODS_ORGANS: '504',
    conREF_ERRORS: '600',
    conREF_PROVISION_GROUPS: '601',
    conREF_PROVISION_GROUPS_STATS: '602',
    conREF_RIGHT_TYPES: '603',
    conREF_AGES: '604',
    conREF_EDUCATIONS: '605',
    conREF_DEC_CLIENT_TYPES: '606',
    conREF_DEC_TYPES: '607',
    conREF_DEC_TYPES_4ORGANS: '608',
    conREF_STATES: '609',
    conREF_DECTYPES_DECCLIENTTYPES: '610',
    conREF_STAT_PROVISION_GROUPS: '611',
    conREF_PROVISION_ELEMENTS: '612',
    conREF_NOTARY_OBJECT_TYPES: '613',
    conREF_NOTARY_ERRORS: '614',
    conREF_NOTARY_DOC_TYPES: '615',
    conREF_NOTARY_ORG_TYPES: '616',
    conREF_NOTARY_ORG_POSTS: '617',
    conREF_NOTARY_SUBJECT_TYPES: '618',
    conREF_PROVISION_OPTIONS: '619',
    conREF_POLICE_ERRORS: '620',
    conREF_POLICE_FUEL_TYPES: '621',
    conREF_POLICE_INSPECT_RESULTS: '622',
    conREF_POLICE_SUBJECT_TYPES: '623',
    conREF_POLICE_VEHICLES: '624',
    conREF_SOURCES: '625',
    conREF_SMALL_BUSINESS: '629',
    conREF_USER_STATES: '630',
    conREF_NOTARY_PASSPORT_TYPE: '632',
    conREF_NOTARY_CAR_TYPES: '634',
    conREF_SOATO_7: '633',
    conREF_NOTARY_BAN_STATE: '635',
    conREF_EMAIL_STATE: '636',
    conREF_STATISTIC_SETTINGS: '639',
    conREF_PROVISION_TYPES_GROUPS:'640',
    conREF_PROTEST_TYPES:'644',
    conREF_PROVISION_TYPES:'111',
    conREF_INTERNAL_SERVICE: '500',
    conREF_EXTERNAL_SERVICE: '997',
    conREF_REFERS_GROUP: '998',
    conREF_REFERS_CHECK: '999'
}