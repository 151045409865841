import { notification, Modal } from "antd";
import HttpError from "../site/HttpError";
const lang = localStorage.getItem("lang");

const errors = {
    ru: { status: 404, msg: 'Ошибка', btn: 'Обновить' },
    oz: { status: 404, msg: 'Xato', btn: 'Yangilash' },
    uz: { status: 404, msg: 'Xato', btn: 'Yangilash' },
    en: { status: 404, msg: 'Error', btn: 'Update' },
};

const getError = () => {
    switch (lang) {
        case 'ru': return errors.ru
        case 'oz': return errors.oz
        case 'uz': return errors.uz
        case 'en': return errors.en
    }
};

export const ErrorsApi = function (err, type) {
    let result = null;
    if (type === 'alert') {
        result = notification.error({ message: err, placement: 'top', duration: 5 });
    }
    else if (type === 'success') {
        result = notification.success({ message: err, placement: 'top', duration: 5 });
    }
    else if (err.code === 'ERR_BAD_RESPONSE') {
        result = notification.error({ message: err.message, placement: 'top', duration: 5 });
    }
    else if (err.code === 'ERR_NETWORK' && err.response.status !== 401) {
        let info = getError();
        result = HttpError(info.status, info.msg, info.btn);
    }
    else if (type === 'global' || err.error) {
        if (err.error) {
            result = notification.error({ message: err.error.message, placement: 'top', duration: 3 });
        }
        let info = getError();
        result = HttpError(info.status, info.msg, info.btn);
    }
    else if (type === true && err.response.status === 401) {
        result = notification.error({ message: err.response.statusText, placement: 'top', duration: 3 });
    }
    return result;
};