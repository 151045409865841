import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Pagination, Divider, Badge, Button, Row, Col } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import MonitoringTable from "../../../templates/MonitoringTable";
import DrawerComponent from "../../../templates/DrawerComponent";
import ViewData from "./components/ViewData";
import FilterForm from "./components/FilterForm";
import Trans, { getTitle } from "../../../Trans";
import { date_format } from "../../../templates/Patterns";
import moment from "moment";
import PaymnentSystemList from "../../../templates/PaymentSystemList";

const PaymentsList = () => {
  const pageSize = 10;
  const defaultFilterForm = {
    bdate: moment(),
    edate: moment(),
  };
  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataDetail, setDataDetail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [sendObj, setSendObj] = useState({
    bdate: moment().format(date_format),
    edate: moment().format(date_format),
    payment_id: null,
    page: 1,
    page_size: pageSize,
  });
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();

  useEffect(() => {
    loadContent();
  }, [sendObj]);

  const loadContent = async () => {
    const result = await hookApi("site/payments/getPayments", {
      data: sendObj,
    });
    if (result.data) {
      setDataSource(result.data.data);
      setDataCount(result.data.count);
    }
  };

  const handleChangePage = async (value) => {
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    const data = {};
    data.bdate = moment(values.bdate).format(date_format);
    data.edate = moment(values.edate).format(date_format);
    data.payment_id = values.payment_id ? values.payment_id * 1 : null;
    data.page = 1;
    setCurrentPage(1);
    setSendObj({ ...sendObj, ...data });
  };

  const info = async (id) => {
    const result = await hookApi("site/payments/getPayment", {
      payment_id: id,
    });
    const dataTable = (
      <ViewData data={result.data} />
    );
    setDataDetail(dataTable);
    setModalOpen(true);
  };

  const columns = [
    {
      title: getTitle("Unique service code"),
      dataIndex: "key",
      key: "key",
      width: '10%',
      align: 'center'
    },
    {
      title: getTitle("Date and time of registration"),
      dataIndex: "registered",
      key: "registered",
      width: '10%',
      align: 'center'
    },
    {
      title: getTitle("Service type"),
      dataIndex: "service_name",
      key: "service_name",
      width: '15%',
      align: 'center'
    },
    {
      title: getTitle("Sum"),
      dataIndex: "amount",
      key: "amount",
      width: '10%',
    },
    {
      title: (getTitle("Pin") + '/' + getTitle("Inn")),
      dataIndex: "client_pin_inn",
      key: "client_pin_inn",
      width: '15%',
      align: 'center'
    },
    {
      title: (getTitle("Company_name") + '/' + getTitle("S.F.P")),
      dataIndex: "client_name",
      key: "client_name",
      width: '20%',
    },
    {
      title: getTitle("Payment system"),
      dataIndex: "center_name",
      key: "center_name",
      width: '10%',
      align: 'center',
    },
    {
      title: getTitle("Service status"),
      dataIndex: "state_name",
      key: "state_name",
      width: '9%',
      align: 'center',
      render: (text, record) => (
        <Badge
          count={record.state_name}
          style={{
            backgroundColor: `${record.state !== 1 ? "#f5222d" : "#52c41a"}`,
          }}
        />
      ),
    },
    {
      title: "",
      key: "buttons",
      width: '1%',
      render: (_, record) => (
        <Button
          disabled={loading}
          type="primary"
          shape="circle"
          icon={<SearchOutlined />}
          title={"Просмотр"}
          onClick={() => info(record.id)}
        />
      ),
    },
  ];

  return (
    <>
      <PaymnentSystemList />
      <FilterForm
        form={filter}
        onFinish={handleFilterForm}
        disabled={loading}
        initialValues={defaultFilterForm}
      />
      <Divider />
      <DrawerComponent
        title={<Trans alias={"Show data"} />}
        open={modalOpen}
        data={dataDetail}
        onCancel={() => setModalOpen(false)}
        width={'50%'}
      />
      <MonitoringTable
        columns={columns}
        size='small'
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        bordered={true}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default PaymentsList;
