import { Col, Row, Checkbox, Form, DatePicker } from 'antd'
import { pattern, date_format } from '../../templates/Patterns';
import GetSelect from '../../templates/FormSelect';
import GetInput from '../../templates/FormInput';
import GetOthers from '../../templates/FormOther';
import { getTitle } from '../../Trans';
import { REFERS } from '../../constants/refers';


const WithoutJur = (props) => {
    return (
        <>
            <h1 className='regist-title-grey-center'>{getTitle('Form individual')}</h1>
            <Row >
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <GetInput name={'name'} pattern={pattern.name_opt} required={true} text={'Company_name'} />
                </Col>
            </Row>
            {/* <Row gutter={5}>
                <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                    <GetSelect name={'org_form'} required={true} func={null} refer={REFERS.conREF_ORGANIZATION_FORMS} text={'Opf'} />
                </Col>
                <Col xxl={8} xl={6} lg={12} md={12} sm={24} xs={24}>
                    <GetSelect name={'oked'} required={true} func={null} refer={REFERS.conREF_OKED} text={'oked'} />
                </Col>
                <Col xxl={4} xl={6} lg={12} md={12} sm={24} xs={24}>
                    <GetSelect name={'small_business'} disabled={!props.checkVal} required={props.checkVal} refer={REFERS.conREF_SMALL_BUSINESS}
                        label={<Checkbox onChange={(e) => props.checked(e)} >{getTitle('small_business')}</Checkbox>} text={'small_business'} />
                </Col>
            </Row> */}
            <Row gutter={5}>
                <Col xxl={9} xl={9} lg={12} md={12} sm={24} xs={24}>
                    <GetSelect name={'branch'} required={true} func={null} refer={REFERS.conREF_BRANCH} search={true} text={'Mfo'} />
                </Col>
                <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'account'} pattern={pattern.account} required={true} text={'Account'} />
                </Col>
                <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'username'} pattern={pattern.login} required={true} text={'Username'} />
                </Col>
                <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'phone'} pattern={pattern.phone} required={true} text={'Phone'} addonBefore={'+'} />
                </Col>
                <Col xxl={5} xl={5} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'email'} pattern={pattern.email} required={true} text={'Email'} />
                </Col>
            </Row>
            <Row gutter={5}>
                <Col xxl={2} xl={2} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'passport_s'} pattern={pattern.passportS} required={true} text={'Passport S'} />
                </Col>
                <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'passport_n'} pattern={pattern.passportN} required={true} text={'Passport N'} />
                </Col>
                <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'pin'} pattern={pattern.pin} required={true} text={'Pin'} />
                </Col>
                <Col xxl={3} xl={3} lg={12} md={12} sm={24} xs={24}>
                    <GetOthers name={'birth_date'} pattern={null} required={true} text={'Birth date'} component={<DatePicker format={date_format} style={{ width: '100%' }} />} />
                </Col>
                <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'last_name'} pattern={pattern.fio} required={true} text={'Last name'} />
                </Col>
                <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'first_name'} pattern={pattern.fio} required={true} text={'First name'} />
                </Col>
                <Col xxl={4} xl={4} lg={12} md={12} sm={24} xs={24}>
                    <GetInput name={'patronymic'} pattern={pattern.fio} required={false} text={'Patronymic'} />
                </Col>
                <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <GetInput name={'address'} required={true} text={'Address'} />
                </Col>
            </Row>
        </>
    )
}

export default WithoutJur;