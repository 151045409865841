import React from "react";
import { Form, Row, Col, Button, DatePicker } from "antd";
import Trans from "../../../../Trans";
import GetSelect from "../../../../templates/FormSelect";
import { REFERS } from "../../../../constants/refers";
import ExportExcel from "../../../../templates/ExportExcel";
import GetOthers from "../../../../templates/FormOther";
import GetInput from "../../../../templates/FormInput";
import { date_format, month_format, pattern } from "../../../../templates/Patterns";
const FilterForm = (props) => {
  const type = Form.useWatch('type', props.form);

  const formConfig = {
    form: props.form,
    onFinish: props.onFinish,
    disabled: props.disabled,
    name: "filterForm",
    layout: "vertical",
    className: "form-back",
    initialValues: props.initialValues,
  };

  return (
    <Form {...formConfig}>
      <Row gutter={5}>
        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
          <GetSelect name={'type'} refer={REFERS.conREF_STATISTIC_SETTINGS} text={'Search'} required={true} func={(value, options) => { props.selectLabel(options.label); props.setType(value); props.resetTable([]); props.setExcel(false); props.setColumns([]); }} />
        </Col>
        {(type === '14' || type === '15') &&
          <>
            <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
              <GetOthers name={'bdate'} pattern={null} required={true} text={'Begin date'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={false} />} />
            </Col>
            <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
              <GetOthers name={'edate'} pattern={null} required={true} text={'End date'} component={<DatePicker format={date_format} style={{ width: '100%' }} disabled={false} />} />
            </Col>
          </>
        }
        {(type === '16' || type === '17') &&
          <>
            <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
              <GetOthers name={'bdate'} pattern={null} required={true} text={'Begin date'} component={<DatePicker picker="month" format={month_format} style={{ width: '100%' }} disabled={false} />} />
            </Col>
            <Col xxl={3} xl={3} lg={3} md={12} sm={24} xs={24}>
              <GetOthers name={'edate'} pattern={null} required={true} text={'End date'} component={<DatePicker picker="month" format={month_format} style={{ width: '100%' }} disabled={false} />} />
            </Col>
          </>
        }
        {(type === '14' || type === '15' || type === '16' || type === '17') &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput pattern={pattern.client_code} required={false} name={"client_code"} text={"Client code"} />
          </Col>
        }
        {(type === '15' || type === '17') &&
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
            <GetInput pattern={pattern.login} required={false} name={"user_name"} text={"Username"} />
          </Col>
        }
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Trans alias={"Search"} />
          </Button>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => { props.resetForm(); }}
          >
            <Trans alias={"Reset"} />
          </Button>
          {props.excel && <ExportExcel disabled={props.disabled} columns={props.columns} fileName={props.fileName} url={`statistic/getStatisticByParam`} sendObj={props.sendObj} />}
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
