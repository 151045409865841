import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Button, Divider, Form, Pagination, Space } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined, SearchOutlined, UsergroupDeleteOutlined } from "@ant-design/icons";
import ApiHooks from "../../../../hooks/ApiHooks";
import Trans, { getTitle } from "../../../Trans";
import MonitoringTable from "../../../templates/MonitoringTable";
import DrawerComponent from "../../../templates/DrawerComponent";
import { FilterForm } from "../components/FilterForm";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import { pattern } from "../../../templates/Patterns";
import { ViewDataJuridical } from "../components/ViewDataJuridical";
import { ViewPrivilages } from "../components/ViewPrivilages";
import { access_, client_role } from "../../../../redux/slices/AuthSlice";
import { clientRoles } from "../../../constants/priveleges";
import ModalComponent from "../../../templates/ModalComponent";

const UsersJuridical = () => {
  const pageSize = 10;
  const loading = useSelector(loadingState);
  const access = useSelector(access_);
  const role = useSelector(client_role);

  const [dataDetail, setDataDetail] = useState(null);
  const [dataPrivilages, setDataPrivilages] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); //modal
  const [modalEdit, setModalEdit] = useState(false); //modal
  const [modalOpenPrivilage, setModalOpenPrivilage] = useState(false); //modal
  const [confirmActive, setConfirmActive] = useState(false); //modal
  const [confirmCancel, setConfirmCancel] = useState(false); //modal

  const [user_id, setUserId] = useState();
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageParam, setPageParam] = useState({ username: "", pin: "", client_code: "", page: 1, page_size: pageSize });

  const [filter] = Form.useForm();
  const hookApi = ApiHooks();

  const [users, setUsers] = useState([]);

  const getUsersJuridical = async () => {
    const users = await hookApi("user/getUsersJuridical", { json: pageParam });
    if (users.code === 0) {
      setUsers(users?.data.data);
      setDataCount(users?.data.count);
    }
  };

  const handleFilterForm = (values) => {
    const { pin, client_code, username } = values;
    setCurrentPage(1);
    setPageParam({ ...pageParam, pin, username, client_code, page: 1 });
  };

  const handleChangePage = (value) => {
    setCurrentPage(value);
    setPageParam({ ...pageParam, page: value });
  };

  const handleChangePrivilage = async (json) => {
    const privilages = await hookApi("user/changeRegisterPrivilege", { json: { ...json } });
    if (privilages.code === 0) {
      await viewPrivilages(json.user_id);
    }
  };

  const onCancel = () => {
    setModalOpen(false);
    setModalOpenPrivilage(false);
  };

  const viewPrivilages = async (id, status) => {
    const result = await hookApi("user/getRegisterPrivileges", { user_id: id });
    const dataPrivilages = (<ViewPrivilages data={result?.data.privileges} handleChangePrivilage={handleChangePrivilage} user_id={id} access={access} status={status} />);
    setDataPrivilages(dataPrivilages);
  };

  const handleViewData = async (id) => {
    const result = await hookApi("user/getUserDetailedJuridical", { user_id: id });
    const dataTable = <ViewDataJuridical data={result?.data.data} />;
    setDataDetail(dataTable);
    setModalOpen(true);
  };

  const handleViewPrivilages = async (id, status) => {
    await viewPrivilages(id, status);
    setModalOpenPrivilage(true);
  };

  const getConfirm = (id, state) => {
    setUserId(id);
    state === 2 && setConfirmActive(true);
    state === 1 && setConfirmCancel(true);
  };

  const confirmEditModal = (id) => {
    setUserId(id);
    setModalEdit(true);
  };

  const onConfirmState = async (id, state) => {
    const client = await hookApi("user/changeUserState", { json: { user_id: id, state } });
    if (client.code === 0) {
      await getUsersJuridical();
      state === 1 && setConfirmActive(false);
      state === 2 && setConfirmCancel(false);
    }
  };

  useEffect(() => {
    getUsersJuridical();
  }, [pageParam]);

  const columns = [
    {
      title: getTitle('Client code'),
      dataIndex: "client_code",
      key: "client_code",
      align: "center",
      fixed: "left",
      width: "5%",
    },
    {
      title: getTitle('User'),
      dataIndex: "client_name",
      key: "client_name",
      width: "20%",
    },
    {
      title: getTitle('User type'),
      dataIndex: "type_name",
      key: "type_name",
      width: "10%",
      align: "center",
    },
    {
      title: getTitle("S.F.P"),
      dataIndex: "fio",
      key: "fio",
      width: "20%",
    },
    {
      title: getTitle("Username"),
      dataIndex: "username",
      key: "username",
      width: "15%",
    },
    {
      title: getTitle("State"),
      dataIndex: "status_name",
      key: "status_name",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Badge
          count={record.status_name}
          style={{ backgroundColor: `${record.status_code === 1 ? "#52c41a" : record.status_code === 2 ? "#f5222d" : "#f5222d"}` }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      fixed: "right",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Space>
          <Button
            disabled={loading}
            title="Просмотр"
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => handleViewData(record.id)}
          />
          <Button
            title="Доступы"
            disabled={loading}
            shape="circle"
            icon={<UsergroupDeleteOutlined />}
            onClick={() => handleViewPrivilages(record.id, record.status_code)}
          />
          {((role === clientRoles.super && record.type !== 3) || (role !== clientRoles.super && record.type === 3)) &&
            <>
              <Button title="Редактировать" disabled={loading} shape="circle" icon={<EditOutlined />} onClick={() => confirmEditModal(record.id)} />
              {record.status_code !== 0 && (
                <Button
                  title="Активировать"
                  disabled={loading || record.status_code === 1}
                  type="success"
                  shape="circle"
                  icon={<CheckOutlined />}
                  onClick={() => getConfirm(record.id, record.status_code)}
                />
              )}
              <Button
                title="Заблокировать"
                disabled={loading || record.status_code === 2}
                type="danger"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => getConfirm(record.id, record.status_code)}
              />
            </>
          }
        </Space>
      ),
    },
  ];

  return (
    <>
      <FilterForm
        form={filter}
        inputs={[
          { name: "username", text: "Username" },
          { name: "pin", text: "Pin", pattern: pattern.pin },
        ]}
        onFinish={handleFilterForm}
        disabled={loading}
      />
      <Divider />
      {access.manage_admin && (
        <Button
          title="Добавить пользователя"
          style={{
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            padding: "15px 10px",
            marginBottom: "10px",
            backgroundColor: "#52c41a",
          }}
          onClick={() => { window.open(`/main/manage/users/user/add`, "_blank") }}
        >
          <PlusOutlined style={{ fontSize: "20px", color: "#fff" }} />
        </Button>
      )}

      <MonitoringTable
        size="small"
        columns={columns}
        dataSource={users}
        loading={false}
        bordered={true}
        pagination={false}
        rowKey={(record) => record.key}
      />
      <DrawerComponent title={<Trans alias={"Show data"} />} open={modalOpen} data={dataDetail} onCancel={onCancel} width={"50%"} />
      <DrawerComponent title={<Trans alias={"Show data"} />} open={modalOpenPrivilage} data={dataPrivilages} onCancel={onCancel} width={"50%"} />
      <ModalComponent
        title={<Trans alias={"Изменения статуса"} />}
        open={confirmActive}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => onConfirmState(user_id, 1)}
        onCancel={() => setConfirmActive(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Editing a post"} />}
        open={modalEdit}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => {
          role === clientRoles.super ?
            window.open(`/main/profile/user/${user_id}`, "_blank") :
            window.open(`/main/manage/users/user/${user_id}`, "_blank");
          setModalEdit(false);
        }}
        onCancel={() => setModalEdit(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      <ModalComponent
        title={<Trans alias={"Изменения статуса"} />}
        open={confirmCancel}
        data={getTitle("Do you really want to change the entry")}
        onOk={() => onConfirmState(user_id, 2)}
        onCancel={() => setConfirmCancel(false)}
        loading={loading}
        cancelTitle={<Trans alias={"No"} />}
        okTitle={<Trans alias={"Yes"} />}
      />
      {dataCount > pageSize && (
        <Pagination
          disabled={loading}
          defaultCurrent={1}
          current={currentPage}
          total={dataCount}
          pageSize={pageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
        />
      )}
    </>
  );
};

export default UsersJuridical;
