import React, { useEffect, useState } from 'react';
import { Select, Form } from 'antd'
import { TransFunc } from '../Trans';
import { postApi } from "../../web/api";
import { clientRoles } from '../constants/priveleges';
import { useSelector } from 'react-redux';
import { client_role } from '../../redux/slices/AuthSlice';

const GetSelectSource = (props) => {
    const [refers, setRefers] = useState([]);
    const client = useSelector(client_role)
    useEffect(() => {
        if (props.refer)
            getRefers(props.refer, props.referGroup);
    }, [props.refer, props.referGroup]);

    const mapArray = async (data) => {
        const arr = [];
        data.map((refers) => (
            arr.push({ value: refers.code, label: refers.name, key: refers.code })
        ));
        return arr;
    };

    const getRefers = async (refer, referGroup) => {
        let data = await postApi('refers/getRefers', { refer, referGroup });
        let arr = [];
        if (props.defaultOption)
            arr.push(props.defaultOption)
        if (data) {
            data = JSON.parse(data)
            let source = [];
            if (client === clientRoles.super) {
                source = data?.filter((value) => value.code !== '-1')
                arr = await mapArray(source);
            }
            else if (client === clientRoles.bank || client === clientRoles.mko) {
                source = data?.filter((value) => (value.code === '1' || value.code === '3'))
                arr = await mapArray(source);
            }
            else if (client === clientRoles.tax) {
                source = data?.filter((value) => value.code === '4')
                arr = await mapArray(source);
            }
            else if (client === clientRoles.customs || client === clientRoles.investigation_body || client === clientRoles.body_of_inquiry) {
                source = data?.filter((value) => value.code === '5')
                arr = await mapArray(source);
            }
            else if (client === clientRoles.physical || client === clientRoles.juridical || client === clientRoles.businessman) {
                source = data?.filter((value) => value.code === '1')
                arr = await mapArray(source);
            }
        }
        setRefers(arr);
    };

    const obj = TransFunc(props.text);
    const required_msg = TransFunc("Props is a must");
    if (obj) {
        return (
            <Form.Item name={props.name} label={props.label ? props.label : obj.NAME} rules={[{ required: props.required, message: required_msg.NAME }]} key={obj.ID}>
                {props.func ? <Select placeholder={obj.NAME} options={refers} value={props.value} defaultValue={props.defaultValue} allowClear onChange={props.func} disabled={props.disabled} showSearch={props.search} /> : <Select placeholder={obj.NAME} options={refers} disabled={props.disabled} showSearch={props.search} defaultValue={props.defaultValue} />}
            </Form.Item>
        );
    }
};
export default GetSelectSource;






