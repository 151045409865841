import { Form, Checkbox, Radio } from "antd";
import { useState, useEffect } from "react";
import { TransFunc } from "../Trans";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { postApi } from "../../web/api";

const GetOthers = (props) => {
    const trans = TransFunc(props.text);
    const required_msg = TransFunc("Props is a must");
    const pattern_msg = TransFunc("Does not match with pattern");
    const [refers, setRefers] = useState([]);

    useEffect(() => {
        if (props.refer)
            getRefers(props.refer);
    }, []);

    const getRefers = async (refer, pRefGroup) => {
        let data = await postApi('refers/getRefers', { refer, pRefGroup });
        if (data) {
            setRefers(JSON.parse(data));
        }
    };

    const getComponents = (component) => {
        let result = null;
        if (component === 'Checkbox') {
            result = <Checkbox onChange={props.onChange} value={props.value} defaultValue={props.defaultValue}>{trans.NAME}</Checkbox>
        }
        else if (component === 'Radio') {
            let option = [];
            if (props.client === 'tax' || props.client === 'customs' || props.client === 'investigation_body' || props.client === 'body_of_inquiry') {
                option = refers?.filter((value) => value.code === '3')
            }
            else if (props.client === 'factoring') {
                option = refers?.filter((value) => (value.code === '4' || value.code === '5'))
            }
            else {
                option = refers?.filter((value) => value.code !== '3')
            }
            result = <Radio.Group onChange={props.onChange}>
                {option?.map((data) => (
                    <Radio.Button value={{ code: data.code, name: data.name }} key={data.code} ><span id={props.elmId}>{data.name}</span></Radio.Button>
                ))}
            </Radio.Group>
        }
        else {
            result = props.component;
        }
        return result
    }
    if (trans) {
        return (
            <Form.Item
                name={props.name}
                label={props.component === 'Checkbox' ? null : trans.NAME}
                valuePropName={props.valuePropName}
                tooltip={props.tooltip ? {
                    title: props.tooltip,
                    icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                    color: 'red'
                } : null}
                rules={props.pattern === null ? [
                    { required: props.required, message: required_msg.NAME }
                ] : [
                    { required: props.required, message: required_msg.NAME },
                    { pattern: props.pattern, message: pattern_msg.NAME },
                ]}
                key={trans.ID}
            >
                {getComponents(props.component)}
            </Form.Item>
        );
    }
};

export default GetOthers;
