import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Checkbox } from 'antd';
import { RedoOutlined, } from "@ant-design/icons";
import moment from "moment";
import SVG from 'react-inlinesvg';
import Physical from './components/physical';
import Juridical from './components/juridical';
import WithoutJur from './components/withoutJur'
import { getTitle } from '../Trans';
import GetSelect from '../templates/FormSelect';
import { getObject } from './components/getObject';
import { getCapt } from '../../web/api';
import { loadingState } from '../../redux/slices/LoadingSlice';
import { useSelector } from 'react-redux';
import ApiHooks from '../../hooks/ApiHooks';
import { REFERS } from '../constants/refers';

const Register = () => {
    const [form] = Form.useForm();
    const [userType, setUserType] = useState('0');
    const [checkSmallB, setCheckSmallb] = useState(false);
    const [captcha, setCaptcha] = useState(null);
    const [content, setContent] = useState(false)
    const loading = (useSelector(loadingState));
    const hookApi = ApiHooks();

    useEffect(() => {
        getCaptcha();
    }, []);

    const checked = (e) => {
        setCheckSmallb(!checkSmallB)
        if (!e.target.checked) {
            form.setFieldValue('small_business', null)
        }
    };

    const getUserForm = () => {
        switch (userType) {
            case '1':
                return <Juridical checked={checked} checkVal={checkSmallB} />;
            case '2':
                return <Physical />;
            case '3':
                return <WithoutJur />;
            default: return <></>;
        };
    };

    const registr = async (values) => {
        let obj = await getObject(values);
        if (userType === '1') {
            obj.data.user.birth_date = moment(values.birth_date).format("DD.MM.YYYY");
        }
        else {
            obj.data.client.birth_date = moment(values.birth_date).format("DD.MM.YYYY");
            obj.data.user.username = values.username;
        }
        obj.captcha = values.captcha;
        form.setFields([{ name: "captcha", errors: [] }]);
        let result = await hookApi('user/registration', obj);
        if (result.code === 77777) {
            form.setFields([{ name: "captcha", errors: [result.msg] }]);
        }
        if (result.code === 0) {
            setContent(true)
        }
        getCaptcha();
    };

    const getCaptcha = async () => {
        let captcha = await getCapt('/security/captcha');
        setCaptcha(
            <SVG src={captcha} />
        )
    };

    return (
        <Row className='backgroundFormRegistr'>
            <Col span={24}><h1 className='regist-title-grey'>{getTitle('Statement')}</h1></Col>
            <Col span={24} style={{ display: content ? 'none' : '' }}>
                <Form form={form} onFinish={registr} layout="vertical" disabled={loading}>
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <GetSelect name={'subject_type'} required={true} func={setUserType} refer={REFERS.conREF_SUBJECTS} text={'Subject_type'} />
                    </Col>
                    {getUserForm()}
                    {userType !== '0' ?
                        <>
                            <Row>
                                <Col xxl={4} xl={6} lg={8} md={8} sm={8} xs={8}>
                                    <Form.Item name='offer'>
                                        <Checkbox required={true} ><a href='https://www.garov.uz/about/offer' target="_blank" rel="noreferrer">Публичная оферта</a></Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={5}>
                                <Col xxl={2} xl={4} lg={8} md={8} sm={8} xs={8}>
                                    {captcha}
                                </Col>
                                <Col xxl={4} xl={6} lg={8} md={8} sm={8} xs={8} style={{ marginTop: '15px' }}>
                                    <Form.Item name='captcha'>
                                        <Input rules={[{ required: true, message: 'Error' }]} addonBefore={<RedoOutlined onClick={() => getCaptcha()} />} />
                                    </Form.Item>
                                </Col>
                                <Col xxl={6} xl={8} lg={8} md={8} sm={8} xs={8}><Button htmlType="submit" className='registr-form-button' style={{ marginTop: '15px' }} loading={loading}>Регистрация</Button></Col>
                            </Row></> : <></>}
                </Form>
            </Col>
            <Col span={24} style={{ display: content ? '' : 'none' }}>
                {getTitle('Success registration')}
            </Col>
        </Row>
    );
};

export default Register