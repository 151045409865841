import { Col, Row, Pagination } from "antd";
import { useEffect } from "react";
import { getTitle } from "../../../../../Trans";
import MonitoringTable from "../../../../../templates/MonitoringTable";

const Statistic_01 = (props) => {

    const titleName = getTitle('name');
    const dec_add_count = getTitle('dec_add_count');
    const dec_del_count = getTitle('dec_del_count');
    const dec_order_count = getTitle('dec_order_count');
    const notary_ban_count = getTitle('notary_ban_count');
    const notary_cancel_count = getTitle('notary_cancel_count');
    const notary_search_count = getTitle('notary_search_count');
    const police_search_count = getTitle('police_search_count');
    const fio = getTitle('User');
    const login = getTitle('Username')
    const stat_date = getTitle('Date');

    const columns = [
        {
            title: '№',
            dataIndex: "order",
            align: 'center'
        },
        {
            title: stat_date,
            dataIndex: "stat_date",
            align: 'center'
        },
        {
            title: login,
            dataIndex: "user_name",
            align: 'center',
            hidden: (!(props.type === '15' || props.type === '17'))
        },
        {
            title: fio,
            dataIndex: "user_fio",
            align: 'center',
            hidden: (!(props.type === '15' || props.type === '17'))
        },
        {
            title: dec_add_count,
            dataIndex: "dec_add_count",
            align: 'center'
        },
        {
            title: dec_del_count,
            dataIndex: "dec_del_count",
            align: 'center'
        },
        {
            title: dec_order_count,
            dataIndex: "dec_order_count",
            align: 'center'
        },
        {
            title: notary_ban_count,
            dataIndex: "notary_ban_count",
            align: 'center'
        },
        {
            title: notary_cancel_count,
            dataIndex: "notary_cancel_count",
            align: 'center'
        },
        {
            title: notary_search_count,
            dataIndex: "notary_search_count",
            align: 'center'
        },
        {
            title: police_search_count,
            dataIndex: "police_search_count",
            align: 'center'
        },
    ].filter(item => !item.hidden);;;

    useEffect(() => {
        props.setColumns(columns);
    }, [props.type]);

    return (
        <Row gutter={5}>
            <Col span={24}>
                <MonitoringTable
                    size='small'
                    columns={columns}
                    dataSource={props.dataSource}
                    loading={props.loading}
                    pagination={false}
                    bordered={true}
                    rowKey={(record) => record.order} />
                {props.dataCount > props.pageSize &&
                    <Pagination
                        disabled={props.loading}
                        defaultCurrent={1}
                        current={props.currentPage}
                        total={props.dataCount}
                        pageSize={props.pageSize}
                        onChange={props.handleChangePage}
                    />
                }
            </Col>
        </Row>
    )
}
export default Statistic_01;

