import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Form, Divider } from "antd";
import ApiHooks from "../../../../hooks/ApiHooks";
import { loadingState } from "../../../../redux/slices/LoadingSlice";
import FilterForm from "./components/FilterForm";
import { date_format } from "../../../templates/Patterns";
import Statistic01 from "./components/table/Statistic_01";
import Statistic02 from "./components/table/Statistic_02";
import Statistic03 from "./components/table/Statistic_03";
import Statistic04 from "./components/table/Statistic_04";
import Statistic05 from "./components/table/Statistic_05";

const GeneralStatForm = () => {

  const [filter] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [excel, setExcel] = useState(false);
  const [selectLabel, setSelectLabel] = useState();
  const [fileName, setFileName] = useState('');
  const [columns, setColumns] = useState([]);
  const [type, setType] = useState();
  const loading = useSelector(loadingState);
  const hookApi = ApiHooks();
  const pageSize = 20;
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const defaultFilterForm = { bdate: moment().startOf('month'), edate: moment() }
  const [sendObj, setSendObj] = useState({ bdate: moment().startOf('month'), edate: moment(), client_code: null, user_name: null, type: null, page: 1, page_size: pageSize, excel: 0 });
  const type_ = Form.useWatch('type', filter);

  useEffect(() => {
    getStatistic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendObj]);

  useEffect(() => {
    setCurrentPage(1);
    setDataCount(0);
  }, [type_]);

  const getStatistic = async () => {
    let url;
    if (sendObj.type) {
      if (sendObj.type === '14' || sendObj.type === '15' || sendObj.type === '16' || sendObj.type === '17') {
        url = `statistic/getStatisticByParam`;
      }
      else {
        url = `statistic/getStatistic`;
      }
      sendObj.excel = 0;
      const result = await hookApi(url, { data: sendObj });
      if (result.code === 0) {
        if (sendObj.type === '14' || sendObj.type === '15' || sendObj.type === '16' || sendObj.type === '17') {
          setDataCount(result.data.count);
          if (sendObj.type === '16' || sendObj.type === '17') {
            setExcel(true);
          }
        }
        setDataSource(result.data.data);
        setFileName(selectLabel);
      }
    }
  };


  const handleChangePage = async (value) => {
    setDataSource([]);
    setCurrentPage(value);
    setSendObj({ ...sendObj, page: value });
  };

  const handleFilterForm = async (values) => {
    let data = {};
    setCurrentPage(1);
    data.page = 1;
    if (values.type === '14' || values.type === '15' || values.type === '16' || values.type === '17') {
      data.bdate = values.bdate ? moment(values.bdate).format(date_format) : null;
      data.edate = values.edate ? moment(values.edate).format(date_format) : null;
      data.client_code = values.client_code ? values.client_code : null;
      data.user_name = values.user_name ? values.user_name : null;
      data.type = values.type;
      setSendObj({ ...sendObj, ...data })
    }
    else {
      data.type = values.type;
      setSendObj({ ...sendObj, ...data })
    }
  };

  const resetForm = () => {
    filter.resetFields();
    filter.submit();
    setDataSource([]);
    setExcel(false);
    setColumns([]);
  }

  return (
    <>
      <FilterForm
        sendObj={sendObj}
        form={filter}
        onFinish={handleFilterForm}
        initialValues={defaultFilterForm}
        disabled={loading}
        resetTable={setDataSource}
        setExcel={setExcel}
        excel={excel}
        selectLabel={setSelectLabel}
        setColumns={setColumns}
        columns={columns}
        fileName={fileName}
        dataSource={dataSource}
        setType={setType}
        resetForm={resetForm}
      />
      <Divider />
      {(type === '1' && dataSource.length > 0) && <Statistic01 dataSource={dataSource} setColumns={setColumns} title={fileName} type={type} />}
      {((type === '2' || type === '12' || type === '13') && dataSource.length > 0) && <Statistic02 dataSource={dataSource} setColumns={setColumns} title={fileName} type={type} />}
      {((type === '3' || type === '4' || type === '5' || type === '6' || type === '7' || type === '8' || type === '9' || type === '10') && dataSource.length > 0) && <Statistic03 dataSource={dataSource} setColumns={setColumns} title={fileName} type={type} />}
      {(type === '11' && dataSource.length > 0) && <Statistic04 dataSource={dataSource} setColumns={setColumns} title={fileName} type={type} />}
      {((type === '14' || type === '15' || type === '16' || type === '17') && dataSource.length > 0) && <Statistic05 dataSource={dataSource} setColumns={setColumns} title={fileName} type={type} handleChangePage={handleChangePage} dataCount={dataCount} currentPage={currentPage} pageSize={pageSize} />}
    </>
  );
};

export default GeneralStatForm;
