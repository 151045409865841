import { Button } from 'antd';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FileExcelOutlined } from "@ant-design/icons";
import ApiHooks from '../../hooks/ApiHooks';

const ExportExcel = (props) => {

    const hookApi = ApiHooks();

    const getData = async (url, data) => {
        data.excel = 1;
        const result = await hookApi(url, { data: data });
        if (result.data.data) {
            if (url === 'statistic/getClientAction') {
                result.data.data.forEach(element => {
                    delete element.client_code;
                    delete element.client_name;
                });
            }
            exportToCSV(result.data.data);
        }
    }

    const exportToCSV = (json) => {
        const header = props.columns.map((obj) => (obj.title));
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileName = props.fileName;
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
        const finalData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(finalData, `${fileName}.xlsx`);
    }
    return (
        <Button type='success' onClick={() => getData(props.url, props.sendObj)} disabled={props.disabled} icon={<FileExcelOutlined />}>Excel</Button>
    )
};
export default ExportExcel;






