import MenuMainComponent from "../site/content/menu_data_components/MenuMainComponent";
import MenuStaticComponent from "../site/content/menu_data_components/MenuStaticComponent";
import ManagementComponent from "../site/content/menu_data_components/ManagementComponent";
import MenuNewsComponent from "../site/content/menu_data_components/MenuNewsComponent";
import ContentComponent from "../site/content/menu_data_components/ContentComponent";
import ModulesComponent from "../site/modules/ModulesComponent";

import WsBank from "../site/modules/monitoring/wsbank/WsBank";
import WsMko from "../site/modules/monitoring/wsmko/WsMko";
import WsGnk from "../site/modules/monitoring/wsgnk/WsGnk";
import WsNibbd from "../site/modules/monitoring/wsnibbd/WsNibbd";
import WsPolice from "../site/modules/monitoring/wspolice/WsPolice";
import WsNotary from "../site/modules/monitoring/wsnotary/WsNotary";
import WsOrgan from "../site/modules/monitoring/wsorgan/WsOrgan";
import WsGrki from "../site/modules/monitoring/grki/WsGrki";
import WsFactoring from "../site/modules/monitoring/wsfactoring/WsFactoring";
import WsMyGov from "../site/modules/monitoring/mygov/WsMyGov";

import EmailUsers from "../site/modules/monitoring/email/users/EmailUsers";
import ContentRoute from "../site/modules/content/ContentRoute";

import RecordFormEdit from "../site/modules/record/edit/RecordFormEdit";
import RecordMonitoringForm from "../site/modules/record/monitoring/RecordMonitoringForm";
import RecordOrderListForm from "../site/modules/record/order/list/RecordOrderListForm";
import RecordOrderSearchForm from "../site/modules/record/order/search/RecordOrderSearchForm";
import PoliceForm from "../site/modules/record/police/PoliceForm";
import NotaryFormSearch from "../site/modules/record/notary/search/NotaryForm";

import CentersList from "../site/modules/payments/centers/CentersList";
import CenterForm from "../site/modules/payments/centers/CenterForm";
import PaymentsLog from "../site/modules/payments/logs/PaymentsLog";
import PaymentsList from "../site/modules/payments/list/PaymentsList";

import ClientFormComponent from "../site/modules/manage/clients/form/ClientFormComponent";
import UsersPhysical from "../site/modules/manage/users/UsersPhysical";
import UsersIndividual from "../site/modules/manage/users/UsersIndividual";
import ClientList from "../site/modules/manage/clients/ClientList";
import UsersJuridical from "../site/modules/manage/users/UsersJuridical";
import UserProfile from "../site/modules/manage/users/UserProfile";
import UpdateUserJuridik from "../site/modules/manage/users/form/components/UpdateUserJuridik";
import { ClientUpdateForm } from "../site/modules/manage/clients/components/ClientUpdateForm";
import NotaryBanForm from "../site/modules/record/notary/ban/NotaryBanForm";
import NotaryCancelBanForm from "../site/modules/record/notary/cancelban/NotaryCancelBanForm";
import EmailRecords from "../site/modules/monitoring/email/records/EmailRecords";
import FormCancelBan from "../site/modules/record/notary/cancelban/components/FormCancelBan";
import RefersFormEdit from "../site/modules/manage/refers/RefersFormEdit"
import ServiceExternalForm from "../site/modules/manage/settings/service/external/ServiceExternalForm";
import ServiceInternalForm from "../site/modules/manage/settings/service/internal/ServiceInternalForm";
import SiteSettingsForm from "../site/modules/manage/settings/site/SiteSettingsForm";
import RequestStatisticsForm from "../site/modules/statistics/internal/RequestStatisticsForm";
import GeneralStatForm from "../site/modules/statistics/general/GeneralStatForm";
import StatSettingsForm from "../site/modules/statistics/settings/StatSettingsForm";
import StatCounting from '../site/modules/statistics/client/StaticticsForm'
import ProtestForm from "../site/modules/record/protest/ProtestForm";
import RecordOrderSearchFormForCB from "../site/modules/record/order/search_cb/RecordOrderSearchForm";
import SmsRecords from "../site/modules/monitoring/sms/smsRecords";


const about = {
  module: "about",
  routes: [
    {
      path: "about",
      element: <MenuMainComponent />,
    },
    {
      path: "about/info",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/structure",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/charter",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/functions",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/regulation",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/laws",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/management",
      element: <ManagementComponent />,
    },
    {
      path: "about/instructions",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/service",
      element: <MenuStaticComponent />,
    },
    {
      path: "about/offer",
      element: <MenuStaticComponent />,
    },
  ],
};
const press_center = {
  module: "press_center",
  routes: [
    {
      path: "press_center",
      element: <MenuMainComponent />,
    },
    {
      path: "press_center/news",
      element: <MenuNewsComponent menuId={17} />,
    },
    {
      path: "press_center/news/:id",
      element: <ContentComponent />,
    },
    {
      path: "press_center/reviews",
      element: <MenuNewsComponent menuId={18} />,
    },
    {
      path: "press_center/news/:id",
      element: <ContentComponent />,
    },
    {
      path: "press_center/adverts",
      element: <MenuNewsComponent menuId={20} />,
    },
    {
      path: "press_center/:id",
      element: <ContentComponent />,
    },
  ],
};
const open_data = {
  module: "open_data",
  routes: [
    {
      path: "open_data",
      element: <MenuMainComponent />,
    },
    {
      path: "open_data/q_report",
      element: <MenuNewsComponent menuId={25} />,
    },
    {
      path: "open_data/q_report/:id",
      element: <ContentComponent />,
    },
    {
      path: "open_data/a_report",
      element: <MenuNewsComponent menuId={26} />,
    },
    {
      path: "open_data/a_report/:id",
      element: <ContentComponent />,
    },
    {
      path: "open_data/purchase",
      element: <MenuNewsComponent menuId={27} />,
    },
    {
      path: "open_data/purchase/:id",
      element: <ContentComponent />,
    },
    {
      path: "open_data/plan",
      element: <MenuNewsComponent menuId={28} />,
    },
    {
      path: "open_data/plan/:id",
      element: <ContentComponent />,
    },
    {
      path: "open_data/indicator",
      element: <MenuNewsComponent menuId={29} />,
    },
    {
      path: "open_data/indicator/:id",
      element: <ContentComponent />,
    },
    {
      path: "open_data/audit",
      element: <MenuNewsComponent menuId={30} />,
    },
    {
      path: "open_data/audit/:id",
      element: <ContentComponent />,
    },
    {
      path: "open_data/other",
      element: <MenuNewsComponent menuId={31} />,
    },
    {
      path: "open_data/other/:id",
      element: <ContentComponent />,
    },
    {
      path: "open_data/resources",
      element: <MenuStaticComponent />,
    },
  ],
};
const faq = {
  module: "faq",
  routes: [
    {
      path: "faq",
      element: <MenuStaticComponent />,
    },
  ],
};
const career = {
  module: "career",
  routes: [
    {
      path: "career",
      element: <MenuMainComponent />,
    },
    {
      path: "career/responsibilities",
      element: <MenuStaticComponent />,
    },
    {
      path: "career/trip",
      element: <MenuNewsComponent menuId={33} />,
    },
    {
      path: "career/trip/:id",
      element: <ContentComponent />,
    },
    {
      path: "career/vacancies",
      element: <MenuStaticComponent />,
    },
  ],
};
const contacts = {
  module: "contacts",
  routes: [
    {
      path: "contacts",
      element: <MenuStaticComponent />,
    },
  ],
};

const profile = {
  module: "profile",
  routes: [
    {
      path: "profile/user/:id",
      element: <UserProfile />,
    },
    {
      path: "profile/client",
      element: <ClientFormComponent />,
    },
  ],
};

const content = {
  module: "content",
  routes: [
    {
      path: "content",
      privilege: ["site.content"],
      element: <ModulesComponent />,
    },
    {
      path: "content/route/:id",
      privilege: ["site.content"],
      element: <ContentRoute />,
    },
  ],
};

const monitoring = {
  module: "monitoring",
  routes: [
    {
      path: "monitoring",
      privilege: ["monitoring.ws.bank", "monitoring.ws.mko", "monitoring.ws.gnk", "monitoring.ws.nibbd", "monitoring.ws.police", "monitoring.ws.notary", "monitoring.ws.organ", "monitoring.email", "monitoring.sms.declaration", "monitoring.ws.grki", "monitoring.ws.factoring", "monitoring.ws.mygov"],
      element: <ModulesComponent />,
    },
    {
      privilege: ["monitoring.ws.bank"],
      path: "monitoring/wsbank",
      element: <WsBank />,
    },
    {
      privilege: ["monitoring.ws.mko"],
      path: "monitoring/wsmko",
      element: <WsMko />,
    },
    {
      privilege: ["monitoring.ws.gnk"],
      path: "monitoring/wsgnk",
      element: <WsGnk />,
    },
    {
      privilege: ["monitoring.ws.nibbd"],
      path: "monitoring/wsnibbd",
      element: <WsNibbd />,
    },
    {
      privilege: ["monitoring.ws.police"],
      path: "monitoring/wspolice",
      element: <WsPolice />,
    },
    {
      privilege: ["monitoring.ws.notary"],
      path: "monitoring/wsnotary",
      element: <WsNotary />,
    },
    {
      privilege: ["monitoring.ws.organ"],
      path: "monitoring/wsorgan",
      element: <WsOrgan />,
    },
    {
      privilege: ["monitoring.email.user"],
      path: "monitoring/email/user",
      element: <EmailUsers />,
    },
    {
      privilege: ["monitoring.email.declaration"],
      path: "monitoring/email/declaration",
      element: <EmailRecords />,
    },
    {
      privilege: ["monitoring.sms.declaration"],
      path: "monitoring/sms/declaration",
      element: <SmsRecords />,
    },
    {
      privilege: ["monitoring.ws.grki"],
      path: "monitoring/grki",
      element: <WsGrki />,
    },
    {
      privilege: ["monitoring.ws.factoring"],
      path: "monitoring/factoring",
      element: <WsFactoring />,
    },
    {
      privilege: ["monitoring.ws.mygov"],
      path: "monitoring/mygov",
      element: <WsMyGov />,
    },
  ],
};

const statistics = {
  module: "statistics",
  routes: [
    {
      path: "statistics",
      privilege: ["statistics.ws.internal", "statistics.general", "statistics.settings", "statistics.client.action_counting"],
      element: <ModulesComponent />,
    },
    {
      privilege: ["statistics.ws.internal"],
      path: "statistics/internal_methods",
      element: <RequestStatisticsForm />,
    },
    {
      privilege: ["statistics.general"],
      path: "statistics/general",
      element: <GeneralStatForm />,
    },
    {
      privilege: ["statistics.settings"],
      path: "statistics/settings",
      element: <StatSettingsForm />,
    },
    {
      privilege: ["statistics.client.action_counting"],
      path: "statistics/client/action_counting",
      element: <StatCounting />,
    }
  ]
};

const record = {
  module: "record",
  routes: [
    {
      privilege: [
        "record.create",
        "record.update",
        "record.delete",
        "record.list.super",
        "record.order.super",
        "record.order.certified",
        "record.searchcb",
        "notary.ban",
        "notary.ban.cancel",
        "notary.search",
        "police.search",
        "record.protest",
        "notary.super",
        "police.super"
      ],
      path: "record",
      element: <ModulesComponent />,
    },
    {
      privilege: ["record.create"],
      path: "record/create",
      element: <RecordFormEdit />,
    },
    {
      privilege: ["record.update"],
      path: "record/edit/:id",
      element: <RecordFormEdit />,
    },
    {
      privilege: [
        "record.create",
        "record.update",
        "record.delete",
        "record.list.super",
        "notary.ban",
      ],
      path: "record/list",
      element: <RecordMonitoringForm />,
    },
    {
      privilege: ["record.order.super", "record.order.certified"],
      path: "record/order/list",
      element: <RecordOrderListForm />,
    },
    {
      privilege: ["record.order.super", "record.order.certified"],
      path: "record/order/search",
      element: <RecordOrderSearchForm />,
    },
    {
      privilege: ["police.search", "police.super"],
      path: "record/police/search",
      element: <PoliceForm />,
    },
    {
      privilege: ["notary.search", "notary.super"],
      path: "record/notary/search",
      element: <NotaryFormSearch />,
    },
    {
      privilege: ["notary.ban"],
      path: "record/notary/addban/:id",
      element: <NotaryBanForm />,
    },
    {
      privilege: ["notary.ban.cancel"],
      path: "record/notary/cancelban/:id",
      element: <FormCancelBan />,
    },
    {
      privilege: ["notary.ban", "notary.ban.cancel", "notary.super"],
      path: "record/notary/list",
      element: <NotaryCancelBanForm />,
    },
    {
      privilege: ["record.protest"],
      path: "record/protest",
      element: <ProtestForm />,
    },
    {
      privilege: ["record.searchcb"],
      path: "record/searchcb",
      element: <RecordOrderSearchFormForCB />,
    },
  ],
};

const payments = {
  module: "payments",
  routes: [
    {
      path: "payments",
      element: <ModulesComponent />,
    },
    {
      privilege: ["payments.centers"],
      path: "payments/centers/:id",
      element: <CenterForm />,
    },
    {
      privilege: ["payments.centers"],
      path: "payments/centers",
      element: <CentersList />,
    },
    {
      privilege: ["payments.logs"],
      path: "payments/logs",
      element: <PaymentsLog />,
    },
    {
      privilege: ["payments.list"],
      path: "payments/list",
      element: <PaymentsList />,
    },
  ],
};

const manage = {
  module: "manage",
  routes: [
    {
      path: "manage",
      element: <ModulesComponent />,
    },
    {
      privilege: ["manage.client.list.super"],
      path: "manage/client/list",
      element: <ClientList />,
    },
    {
      path: "manage/client/update/:id",
      element: <ClientUpdateForm />,
    },
    {
      path: "manage/users/list/juridical",
      privilege: ["manage.user.list.super", "manage.user.list.administrator"],
      element: <UsersJuridical />,
    },
    {
      path: "manage/users/user/:id",
      element: <UpdateUserJuridik />,
    },
    {
      path: "manage/users/user/add",
      element: <UpdateUserJuridik destination="add" />,
    },
    {
      path: "manage/users/list/physical",
      privilege: ["manage.user.list.super"],
      element: <UsersPhysical />,
    },
    {
      path: "manage/users/list/businessman",
      privilege: ["manage.user.list.super"],
      element: <UsersIndividual />,
    },
    {
      path: "manage/ref/lang",
      privilege: ["manage.ref.list.super"],
      element: <RefersFormEdit />,
    },
    {
      path: "manage/config/service/external",
      privilege: ["config.super"],
      element: <ServiceExternalForm />,
    },
    {
      path: "manage/config/service/internal",
      privilege: ["config.super"],
      element: <ServiceInternalForm />,
    },
    {
      path: "manage/config/site",
      privilege: ["config.super"],
      element: <SiteSettingsForm />,
    },
  ],
};

export const AppRouteComponents = [
  about,
  press_center,
  open_data,
  career,
  faq,
  contacts,
  profile,
  content,
  monitoring,
  statistics,
  record,
  payments,
  manage,
];
